.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 30px;
}

.contact-photo {
    width: 300px;
    height: 450px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.contact-panel {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.contact-panel h1 {
    text-align: center;
    width: 100%;
    color: var(--color-primary);
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
    margin-top: -30px;
}

.contact-text p {
    margin: 5px 0;
    font-size: 1.2rem;
    color: var(--color-primary);
}

.contact-social {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

.contact-social button {
    cursor: pointer;
}

.contact-panel button {
    margin-top: 20px;
    align-self: center;
}

/* Responsivo para mobile */
@media (max-width: 768px) {

    .contact-container {
        margin-top: 30px;
        gap: 10px;
    }

    .contact-photo {
        width: 330px;
        height: 450px;
        object-fit: cover;
    }
    
    .contact-panel {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }
    
    .contact-panel h1 {
        text-align: center;
        width: 100%;
        color: var(--color-primary);
        font-size: 2rem;
        letter-spacing: 0.2rem;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .contact-text p {
        margin: 5px 0;
        font-size: 1rem;
        color: var(--color-primary);
    }

}