.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-primary, #FFA590);
    color: #fff;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0.8;
    z-index: 1000;
}

.scroll-top:hover {
    opacity: 1;
    transform: scale(1.1);
}

.scroll-top svg {
    font-size: 20px;
}
