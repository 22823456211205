.section-books {
    padding: 100px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.books-title {
    text-align: center;
}

.books-title h1{
    color: var(--color-primary);
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
}

.books-title p {
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: -30px;
    margin-bottom: 0px;
}

.book-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-top: 18px;
    margin-bottom: 10px;
}

.text-loading-image {
    height: 40vh;
    color: var(--color-primary);
}

.book-photo-all {
    width: 294px;
    height: 294px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}
  
.book-photo-horizontal {
    width: 300px;
    height: 300px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.popup-image {
    max-width: 100%;
    max-height: 80%;
}

.close-btn-image {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #FFA590;
    color: #363636;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 10px;
}

.nav-btn-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #56413E;
    color: #FFF1D7;
    border: none;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
}

.nav-btn-image.left {
    left: 20px;
}

.nav-btn-image.right {
    right: 20px;
}

.close-btn-image:hover {
    background: #ffa490d5;
}

.nav-btn-image:hover {
    background: #57423ec4;
}

.book-panel > div {
    display: grid;
    gap: 10px;
    justify-content: center;
}

/* Responsivo para mobile */
@media (max-width: 768px) {

    .section-books {
        padding: 80px 0px 0px 0px;
    }
    
    .books-title h1{
        font-size: 2rem;
        letter-spacing: 0.2rem;
    }
    
    .books-title p {
        font-size: 0.8rem;
        margin-top: -20px;
    }

    .text-loading-image {
        height: 60vh;
    }

    .book-photo-all {
        width: 180px;
        height: 180px;
    }
      
    .book-photo-horizontal {
        width: 180px;
        height: 180px;
    }

    .popup-image {
        max-width: 84%;
        max-height: 100%;
    }

    .book-panel {
        margin-bottom: 0px;
    }
    
    .book-panel > div {
        display: grid;
        gap: 10px;
        justify-content: center;
    }
}

@media (min-width: 1343px) {
    .section-books {
        padding: 100px 20px 0px 20px;
    }
}

