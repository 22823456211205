.gallery-title {
    text-align: center;
    margin-bottom: 30px;
}

.gallery-title h1 {
    color: var(--color-primary);
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
}

.gallery-title p {
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: -20px;
    margin-bottom: 40px;
}

.gallery-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-top: 10px;
}

.gallery-photo-vertical {
    width: 300px;
    height: 550px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.gallery-photo-horizontal {
    width: 270px;
    height: 270px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.gallery-panel > div {
    display: grid;
    grid-template-columns: repeat(2, 270px);
    gap: 10px;
    justify-content: center;
}

.gallery-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.gallery-button a {
    display: inline-block;
    padding: 2px 55px;
    background-color: var(--background-button-simple);
    color: var(--color-secundary);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.gallery-button a:hover {
    background-color: var(--background-button-simple-hover);
    transform: translateY(-3px);
}

.gallery-button a:active {
    transform: translateY(1px);
}

.gallery-footer {
    background-color: var(--background-secondary);
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.gallery-footer img {
    user-select: none;
    -webkit-user-drag: none;
}

/* Responsivo para mobile */
@media (max-width: 768px) {

    .gallery-title {
        text-align: center;
        margin-bottom: 30px;
        margin-top: -15px;
    }
    
    .gallery-title h1 {
        font-size: 2rem;
    }
    
    .gallery-title p {
        font-size: 0.8rem;
        margin-bottom: 30px;
    }
    
    .gallery-photo-vertical {
        width: 330px;
        height: 420px;
        object-fit: cover;
    }
    
    .gallery-photo-horizontal {
        width: 160px;
        height: 160px;
        object-fit: cover;
    }
    
    .gallery-panel > div {
        display: grid;
        grid-template-columns: repeat(2, 160px);
        gap: 10px;
        justify-content: center;
    }
    
    .gallery-footer {
        height: 120px;
        margin-top: 30px;
    }

    .gallery-footer img {
        height: 80px;
    }

}