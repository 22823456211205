/* Estilos do Catalogos */
.catalog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalog-title {
    text-align: center;
    margin-bottom: 20px;
}

.catalog-title h1 {
    color: var(--color-primary);
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
}

.catalog-title p {
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: -20px;
}

.catalog-photos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

.catalog-special {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 490px;
    height: 470px;
    overflow: hidden;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}

.catalog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.05); /* Mantém o efeito de zoom */
}

.catalog-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    color: white;
}

.catalog-topSide, .catalog-botSide {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalog-botSide {
    margin-bottom: 15px;
}

.catalogSpecial-title {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
}

.catalog-description {
    font-size: 1.2rem;
    margin-bottom: 15px;
    width: 80%;
}

.catalog-button {
    background: var(--background-button-catalogSpecial);
    color: var(--color-button-catalogSpecial);
    font-size: 1.2rem;
    padding: 15px 50px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.catalog-button:hover {
    background: var(--background-button-catalogSpecial-hover);
    transform: scale(1.1);
}



.catalog-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 50%;
    gap: 10px;
    height: 100%;
    padding-left: 10px;
}

.catalog-group > div {
    display: flex;
    gap: 10px;
    margin: 0;
}

.catalog-group img {
    width: 240px; 
    height: 230px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}


/* Estilos dos Menus */
.catalog-menu {
    background: linear-gradient(to bottom, #FFA590 60%, var(--background-primary) 60%);
    width: 100%;
    text-align: center;
    margin-top: 30px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;
}

.catalog-menu p {
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 100;
    margin-bottom: 30px;
    margin-top: 20px;
}

.menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.menu-options {
    position: relative;
    width: 30%;
}

.menu-options img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.menu-title {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-secundary);
    padding: 10px 20px;
    width: 200px;
}

.menu-title p {
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

/* Responsivo para mobile */
@media (max-width: 768px) {

    /* Estilos do Catalogos */
    .catalog-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .catalog-title {
        margin-bottom: 10px;
    }

    .catalogSpecial-title {
        font-size: 2rem;
    }
    
    .catalog-description {
        font-size: 1rem;
    }
    
    .catalog-button {
        background: #FFA590;
        color: #56413E;
        font-size: 1rem;
        padding: 15px 50px;
    }

    .catalog-photos {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1100px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .catalog-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 50%;
        gap: 10px;
        height: 100%;
        padding-left: 0px;
    }

    .catalog-group img {
        width: 160px; 
        height: 150px;
        object-fit: cover;
    }

    /* Campanha Especial */

    .catalog-special {
        display: flex;
        width: 330px;
        height: 100%;
        overflow: hidden;
        margin-right: 0px;
    }
    
    .catalog-special img {
        width: 330px;
        height: 330px;
        object-fit: cover;
    }
    
    .catalog-special > img {
        flex: 1 1 60%;
        margin: 0;
    }
    
    .catalog-special h1 {
        font-size: 0.9rem;
        text-align: center;
        padding: 5px 10px;
    }
    
    .catalog-special-text {
        position: absolute;
        top: 37px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.6rem;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
    }
    
    .catalog-special-img {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: white;
    }
    
    .catalog-special-link {
        color: var(--color-secundary);
        font-weight: bold;
        text-decoration: none;
        background-color: var(--color-primary);
        padding: 10px 50px;
        display: inline-block;
        transition: background-color 0.3s, transform 0.3s;
    }

    /* Estilos dos Menus */
    .catalog-menu {
        background: linear-gradient(to bottom, #FFA590 60%, var(--background-primary) 60%);
        width: 100%;
        text-align: center;
        margin-top: 30px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 0;
        margin-bottom: -10px;
    }

    .catalog-menu p {
        color: var(--color-primary);
        font-size: 0.8rem;
        font-weight: 100;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .menu-container {
        margin: 0;
        display: flex;
        justify-content: center;
        gap: 12px;
    }

    .menu-options {
        position: relative;
        width: 25%;
    }

    .menu-options img {
        width: 97px;
        height: auto;
        display: block;
        object-fit: cover;
    }

    .menu-title {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--color-secundary);
        width: 60px;
        padding: 5px 8px;
    }

    .menu-title p {
        font-size: 0.6rem;
        margin: 0;
    }
}