.intro-container {
    position: relative; 
    width: 100%; 
    height: 100vh; 
}

.intro-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.intro-text {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    z-index: 2;
    text-align: center; 
    margin-top: -100px;
}

.intro-text h1 {
    color: var(--color-secundary);
    font-size: 2.4rem;
}

.intro-text p {
    color: var(--color-secundary);
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: -20px;
}

.intro-text-bottom {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
}

.intro-text-bottom p {
    color: var(--color-secundary);;
    z-index: 2;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 100;
}

/* Responsivo para mobile */
@media (max-width: 768px) {
    
    .intro-text h1 {
        font-size: 1.8rem;
        width: 360px;
    }
    
    .intro-text p {
        font-size: 0.9rem;
        margin-top: -10px;
    }
    
    .intro-text-bottom p {
        font-size: 0.8rem;
    }
}