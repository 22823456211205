.header-secundary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 15px;
}

.header-secundary-img {
    height: 60px;
    margin-left: 60px;
    cursor: pointer;
    user-select: none;
    -webkit-user-drag: none;
}

/* Responsivo para mobile */
@media (max-width: 768px) {
    
    .header-secundary-img {
        height: 30px;
        margin-left: 30px;
    }
}