.loading-home {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-primary);
    z-index: 50;
    overflow: hidden;
}

.logo-loading {
    user-select: none;
    -webkit-user-drag: none;
}
  
.mask-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--background-primary);
}

@media (max-width: 768px) {

    .logo-loading {
        width: 300px;
    }
}